<script>
import ezfPageHeader from '@/pages/layout/header.vue';
import ezfPageFooter from '@/pages/layout/footer.vue';
import { mapGetters } from 'vuex';
import { GET_VISIBLE_BUTTON_ERROR } from '@/store/builder/getters';

export default {
  name: 'ezfLayout',
  components: {
    ezfPageHeader,
    ezfPageFooter,
  },
  computed: {
    ...mapGetters('builder', {
      getVisibleErrorButton: GET_VISIBLE_BUTTON_ERROR,
    }),
    show() {
      return this.$store.getters[`${this.$quickStartVideo.moduleName}/show`];
    },
    setButtonOffsetY() {
      return this.getVisibleErrorButton ? 100 : 0;
    },
  },
  methods: {
    openQuickStart() {
      this.$quickStartVideo.toggleShow(true);
    },
  },
};
</script>

<template>
  <ez-layout name="funnels" :showQuickStartPanel="show">
    <ezf-page-header slot="header" />
    <router-view slot="content" @openQuickStart="openQuickStart" />
    <ezf-page-footer slot="footer" />

    <template #quick>
      <QuickStartVideo :offsetY="setButtonOffsetY" />
    </template>
  </ez-layout>
</template>
